
import { defineComponent } from 'vue'
import { BillDetailDto, BillDto } from '@/api/bill'
import { dateFormat, downLoadFile } from '@/utils/util'
// import { PeopleListDto } from '@/api/long-insure'
import { updateEnsureWorker } from '@/api/export'
import {
    getDerivationRoster,
    disposableTransposingArr
} from '@/api/long-insure'
import { queryInsureRecord, queryPlans } from '@/api/insure-deduction'
import changeFactory from '@/components/chang-factory.vue'
import store from '@/store/index'
import axios from 'axios'
export default defineComponent({
    name: 'InsureWorker',
    components: {
        changeFactory
    },
    data () {
        return {
            isBatchPay: false,
            pageSize: 10,
            pageNum: 1,
            total: 0,
            tableData: [] as BillDetailDto[],
            searchText: '',
            searchValue: '',
            detailInfo: {} as BillDto,
            selects: [] as string[],
            isDeletePopup: false,
            idCardNo: '',
            personnelInfo: [] as BillDetailDto[],
            renewalManufactory: [] as disposableTransposingArr[],
            conmpanyPlanId: 0,
            isFactory: false,
            selectTime: dateFormat('YYYY-mm-dd', new Date()), // 筛选选择的时间
            plansList: [],
            comboCode: ''
        }
    },
    created () {
        this.getQueryInsureRecord()
        this.getQueryPlans()
    },
    mounted () {
        // 获取完数据调用
        this.$nextTick(() => {
            // this.$refs.dataTable.toggleAllSelection()
        })
        this.$Bus.on('close2', (e) => {
            console.log(e, 'eeee')
            if (e) {
                this.isFactory = false
            }
        })
    },
    methods: {
        async getDerivationRoster () {
            const result = await updateEnsureWorker({
                searchText: this.searchText,
                queryDate: dateFormat('YYYY-mm-dd', new Date(this.selectTime)),
                comboCode: this.comboCode,
				productType: 0 // 0是日日保 1是团意保
            }, {
                loading: true
            })
            console.log(result, 'result')
            if (!result.fileUrl && result.exportStatus === 0) {
                store.commit('getLongPollingFile', result)
            } else {
                axios({
                    url: result.fileUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then(async (response) => {
                    await downLoadFile(response, result.exportType, result.updateTime, result.remark)
                    await this.$message.success('导出成功！')
                })
            }
        },
        handleSizeChange () {
            this.pageNum = 1
			this.getQueryInsureRecord()
        },
        rowKeyInit (row: any) {
            return row.id
        },
        search () {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.getQueryInsureRecord()
        },
        onReset () {
            this.searchText = ''
            this.comboCode = ''
            this.search()
        },
        handleSelectionChange (selects: { id: string }[]) {
            this.selects = selects.map(item => item.id)
        },
        handleSelectionChangeOne (selects: any[]) {
            console.log(selects, 'selectsselectsselects')
            this.selects = selects.map(item => item.id)
            console.log(this.selects, 'this.selectsthis.selectsthis.selects')
            // this.conmpanyPlanId =
            this.renewalManufactory = selects.map(item => {
                return {
                    id: item.id, // 要换厂的人员列表id
                    idCardNo: item.idCardNo, // 人员身份证
                    occupationId: item.occupationId, // 工种id（客户要换厂的工种id）
                    occupationName: item.occupationName, // 工种名（客户要换厂的工种名）
                    serveCompanyId: item.serveCompanyId, // 被派遣单位id（要换厂的id）
                    serveCompanyName: item.serveCompanyName, // 被派遣单位名称（要换厂的名称）
                    userName: item.userName, // 用户名
                    companyPlanId: item.companyPlanId
                }
            })
            console.log(this.renewalManufactory, 'renewalManufactory')
        },
        // 获取日日保险在保人员名单
        async getQueryInsureRecord () {
            const result = await queryInsureRecord({
                searchText: this.searchText,
                queryDate: dateFormat('YYYY-mm-dd', new Date(this.selectTime)),
                comboCode: this.comboCode,
				pageSize: this.pageSize,
				pageNum: this.pageNum,
                productType: 0 // 0是日日保 1是团意保
            })
			// console.log(result, 'result')
            this.tableData = result.rows
			this.total = result.total
        },
        // 获取日日保方案列表信息
        async getQueryPlans () {
            const result = await queryPlans({
                productType: 0 // 0是日日保 1是团意保
            })
            this.plansList = result.map((item:any) => {
                return {
                    value: item.wesurePlanCode,
                    label: item.industryPlanName
                }
            })
            // plansList
            console.log(result, 'result')
        }
    }
})
