
import axios from 'axios'
import { defineComponent } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { dateFormat, shake, downLoadFile } from '@/utils/util'
import searchComponent from '@/components/search-component/index.vue'
import tableComponent from '@/components/table-component/index.vue'
import advanceCheckList from '../../account-check/advance-check-list.vue';
import { servercompanybillapi, exportBill } from '@/api/salary'
import { queryAdvanceAccountCheck, exportAdvanceAccountCheck } from '@/api/advance.ts';

import dayjs from 'dayjs'
import store from '@/store/index'
import cashInfo from './components/cash-info.vue';

const fristDay = dayjs().startOf('month').format('YYYY-MM-DD')
const lastDay = dayjs().endOf('month').format('YYYY-MM-DD')
export default defineComponent({
  name: 'Bill',
  components: {
    searchComponent,
    tableComponent,
    advanceCheckList,
    cashInfo
  },
  data() {
    return {
      // 发薪对账
      searchListPay: {
        paySalaryDate: {
          name: '发薪时间',
          prop: 'Time',
          type: 'dateRange',
          startPlaceholder: '开始时间',
          endPlaceholder: '结束时间',
          value: [fristDay, lastDay],
          defaultValue: [fristDay, lastDay],
          unClearable: true
        },
        companyName: {
          name: '所属企业',
          prop: 'serveCompanyName',
          type: 'input',
          placeholder: '请输入派遣单位',
          value: '',
          defaultValue: ''
        },
        // staffName: {
        //   name: '搜索员工',
        //   prop: 'staffName',
        //   type: 'input',
        //   placeholder: '请输入员工名称',
        //   value: '',
        //   defaultValue: ''
        // }
      } as any,
      // 预支对账
      searchListAdvance: {
        paySalaryDate: {
          name: '发薪时间',
          prop: 'Date',
          type: 'dateRange',
          startPlaceholder: '开始时间',
          endPlaceholder: '结束时间',
          value: [fristDay, lastDay],
          defaultValue: [fristDay, lastDay],
          unClearable: true
        },
        companyName: {
          name: '所属企业',
          prop: 'serveCompanyName',
          type: 'input',
          placeholder: '请输入派遣单位',
          value: '',
          defaultValue: ''
        },
        searchText: {
          name: '搜索员工',
          prop: 'searchText',
          type: 'input',
          placeholder: '请输入员工名称',
          value: '',
          defaultValue: ''
        }
      } as any,

      buttonList: [
        // {
        //   name: '导出表格',
        //   icon: 'export',
        //   type: '',
        //   disabled: false,
        //   fn: () => {
        //     this.exporttable()
        //   }
        // }
      ] as any,
      tableHeadPay: [
        {
          prop: 'index'
        },
        {
          prop: 'serveCompanyName',
          label: '企业名称'
        },
        {
          prop: 'payOrderCount',
          label: '发薪单数',
          suffix: '单',
          defaultValue: 0
        },
        {
          prop: 'payTotalCount',
          label: '发薪人数',
          suffix: '人',
          defaultValue: 0
        },
        {
          prop: 'payTotalMoney',
          label: '发薪金额',
          prefix: '￥',
          defaultValue: '0元'
        },
        {
          prop: 'payReceiveTotalMoney',
          label: '已领金额',
          prefix: '￥',
        },
        {
          prop: 'payUnclaimedTotalMoney',
          label: '待领金额',
          prefix: '￥',
          width: 160,
          defaultValue: '0元'
        },
        {
          prop: 'payStopTotalMoney',
          prefix: '￥',
          label: '停发金额',
          defaultValue: '0元'
        },
        {
          prop: 'operate',
          label: '操作',
          width: 160,
          fixed: 'right'
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ] as any,
      tableDataPay: [
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ] as any,
      tableHeadAdvance: [
        // {
        //   prop: 'index'
        // },
        {
          prop: 'userName',
          label: '预支人员'
        },
        {
          prop: 'idCardNo',
          label: '身份证号',
          width: 170
        },
        {
          prop: 'phone',
          label: '手机号',
          width: 120
        },
        {
          prop: 'totalCount',
          label: '预支单数',
        },
        {
          prop: 'allAmount',
          label: '预支金额',
          prefix: '￥',
          defaultValue: '0'
        },
        {
          prop: 'alrAmount',
          label: '预支已领',
          prefix: '￥',
          defaultValue: '0',
          color: '#00D679'
        },
        {
          prop: 'aitAmount',
          label: '预支待领',
          prefix: '￥',
          defaultValue: '0'
        },
        {
          prop: 'stopAmount',
          label: '预支停发',
          prefix: '￥',
          defaultValue: '0',
          color: '#FF7642'
        },
        {
          prop: 'operate',
          label: '操作',
          width: 160,
          fixed: 'right'
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ] as any,
      tableDataAdvance: [] as any,
      pageInfoPay: {
        pageNum: 1,
        pageSize: 10,
        total: 0
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any,
      pageInfoAdvance: {
        pageNum: 1,
        pageSize: 10,
        total: 0
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any,
      approvalId: '',
      isApprovalPopup: false,
      selectsPay: [],
      selectsAdvance: [],
      userId: 0,
      accountExpireInfoStatus: 0,
      isBatchPay: false,
      activeName: 'Pay'
    }
  },
  mounted() {
    this.getPayData()
  },
  methods: {
    exporttable() {
      exportBill({
        serveCompanyName: this.searchListPay.companyName.value,
        beginPayTime: this.searchListPay.paySalaryDate.value[0],
        endPayTime: this.searchListPay.paySalaryDate.value[1],
        serveCompanyIds: this.selectsPay.join(',')
      }).then((res: any) => {
        if (!res.fileUrl && res.exportStatus === 0) {
          store.commit('getLongPollingFile', res)
        } else {
          // window.location.href = res.fileUrl
          axios({
            url: res.fileUrl,
            method: 'GET',
            responseType: 'blob'
          }).then(async (response: any) => {
            console.log(response, 'responseresponseresponse')
            await downLoadFile(response, res.exportType, res.updateTime, res.remark)
            await this.$message.success('导出成功！')
          })
          // this.$message.success('导出成功！')
        }
      })
    },
    // 发薪对账列表
    getPayData() {
      const query = this.createQuery(this.searchListPay, this.pageInfoPay)
      servercompanybillapi(query).then((res: any) => {
        this.tableDataPay = res.list
        this.pageInfoPay.total = res.total
      })
    },
    //预支对账列表
    getAdvanceData() {
      const query = this.createQuery(this.searchListAdvance, this.pageInfoAdvance)
      queryAdvanceAccountCheck(query).then((res: any) => {
        this.tableDataAdvance = res.list
        this.pageInfoPay.total = res.totalCount
      })
    },

    handleSelectionChange(row: any) {
      if(this.activeName ==='Pay') {
        this.selectsPay = row.map((item: any) => item.serveCompanyId)
      } else if(this.activeName ==='Advance') {
        this.selectsAdvance = row.map((item: any) => item.idCardNo)
      }
    },
    createQuery(searchList: any, pageInfo?: any) {
      const query = { } as any
      if (pageInfo) {
        query.pageNum = pageInfo.pageNum
        query.pageSize = pageInfo.pageSize
      }
      for (const key in searchList) {
        if (searchList[key].type === 'dateRange') {
          query[`start${searchList[key].prop}`] = searchList[key].value?.length ? searchList[key].value[0] : ''
          query[`end${searchList[key].prop}`] = searchList[key].value?.length ? searchList[key].value[1] : ''
        } else {
          query[(searchList[key]).prop] = searchList[key].value
        }
      }
      return query
    },
    handleSearch() {
      (this as any)[`pageInfo${this.activeName}`].pageNum = 1
      this.getData()
    },
    handleSizeChange(ev: number) {
      (this as any)[`pageInfo${this.activeName}`].pageSize = ev
      this.getData()
    },
    handleCurrentChange(ev: number) {
      (this as any)[`pageInfo${this.activeName}`].pageNum = ev
      this.getData()
    },
    getData() {
      if (this.activeName === 'Pay') {
        this.getPayData()
      } else if (this.activeName === 'Advance') {
        this.getAdvanceData()
      }
    },
    handleArchive(row: any) {
      this.$router.push({
        path: '/bill/detail',
        query: {
          serveCompanyId: btoa(row.serveCompanyId),
          startTime: btoa(this.searchListPay.paySalaryDate.value[0]),
          endTime: btoa(this.searchListPay.paySalaryDate.value[1])
        }
      })
    },
    handleToDetail(row: any) {
      this.$router.push({
        path: '/bill/advanceDetail',
        query: {
          idCardNo: btoa(row.idCardNo),
        }
      })
    },
    handleTabClick() {
      this.getData()
    },
    handleExport() {
      if (this.activeName === 'Pay') {
        this.exporttable()
      } else if (this.activeName === 'Advance') {
        this.handleExportAdvaceList()
      }
    },
    // 导出预支对账列表
    handleExportAdvaceList() {
      const query = this.createQuery(this.searchListPay)
      query.startDate = query.queryTimeStart
      query.endDate = query.queryTimeEnd
      query.ids = this.selectsAdvance.join(',')
      exportAdvanceAccountCheck(query).then(res => {
        window.location.href =
                process.env.VUE_APP_BASE_URL +
                    '/common/download?delete=true&fileName=' +
                    res
            this.$message.success('导出成功！')
      })
    }
  }
})
