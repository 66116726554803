
import { defineComponent } from 'vue'
import tableComponent from '@/components/table-component/index.vue';
import router from '@/router';

export default defineComponent({
    components: {
        tableComponent
    },
    data() {
        return {
            tableHead: [
                {
                    prop: 'index'
                },
                {
                    prop: 'serveCompanyName',
                    label: '预支人员'
                },
                {
                    prop: 'payOrderCount',
                    label: '身份证号',
                },
                {
                    prop: 'payTotalCount',
                    label: '手机号',
                },
                {
                    prop: 'payTotalMoney',
                    label: '预支单数',
                },
                {
                    prop: 'payReceiveTotalMoney',
                    label: '预支金额',
                    prefix: '￥',
                    defaultValue: '0'
                },
                {
                    prop: 'payUnclaimedTotalMoney',
                    label: '预支已领',
                    prefix: '￥',
                    width: 160,
                    defaultValue: '0'
                },
                {
                    prop: 'payStopTotalMoney',
                    label: '预支待领',
                    prefix: '￥',
                    defaultValue: '0'
                },
                {
                    prop: 'payStopTotalMoney',
                    label: '预支停发',
                    prefix: '￥',
                    defaultValue: '0'
                },
                {
                    prop: 'operate', 
                    label: '操作',
                    width: 160,
                    fixed: 'right'
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            tableData: [
                {
                    serveCompanyName: '小明'
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            pageInfo: {
                pageNum: 1,
                pageSize: 10,
                total: 0
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
            selects: [] as any,
        }
    },
    methods: {
        getData() {
            // queryAdvanceAccountCheck() {}
            console.log(111);
        },
        handleSelectionChange(row: any) {
            this.selects = row.map((item: any) => item.serveCompanyId)
        },
        handleSizeChange(ev: number) {
            this.pageInfo.pageSize = ev
            this.getData()
        },
        handleCurrentChange(ev: number) {
            this.pageInfo.pageNum = ev
            this.getData()
        },
        toDetail(row: any) {
            this.$router.push('/bill/advanceDetail')
        }
    }
})
