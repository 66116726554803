import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_tableComponent = _resolveComponent("tableComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_tableComponent, {
      class: "mg-t-10",
      "table-data": _ctx.tableData,
      "table-head": _ctx.tableHead,
      onSelectionChange: _ctx.handleSelectionChange,
      "page-info": _ctx.pageInfo,
      onSizeChange: _ctx.handleSizeChange,
      onCurrentChange: _ctx.handleCurrentChange
    }, {
      operate: _withCtx((scope) => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_button, {
            type: "text",
            size: "small",
            onClick: _withModifiers(($event: any) => (_ctx.toDetail(scope.row)), ["stop"])
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("查看详情")
            ])),
            _: 2
          }, 1032, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["table-data", "table-head", "onSelectionChange", "page-info", "onSizeChange", "onCurrentChange"])
  ]))
}