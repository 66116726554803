import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "header block" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "search" }
const _hoisted_4 = { class: "flex.align" }
const _hoisted_5 = { class: "container block block-alone" }
const _hoisted_6 = { class: "table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_export_button = _resolveComponent("export-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_changeFactory = _resolveComponent("changeFactory")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[9] || (_cache[9] = _createElementVNode("text", null, "生效时间： ", -1)),
            _createVNode(_component_el_date_picker, {
              modelValue: _ctx.selectTime,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectTime) = $event)),
              type: "date",
              placeholder: "选择时间"
            }, null, 8, ["modelValue"]),
            _cache[10] || (_cache[10] = _createElementVNode("text", null, "方案类型：", -1)),
            _createVNode(_component_el_select, {
              modelValue: _ctx.comboCode,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.comboCode) = $event)),
              class: "screen-i search-type"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.plansList, (item, index) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    label: item.label,
                    value: item.value,
                    key: index
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "请输入姓名/身份证/被派遣单位名称",
              class: "input search-input",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchText) = $event)),
              onKeydown: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)), ["enter"]))
            }, null, 544), [
              [_vModelText, _ctx.searchText]
            ]),
            _createElementVNode("button", {
              class: "btn",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)))
            }, "搜索"),
            _createElementVNode("button", {
              class: "btn-white",
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onReset && _ctx.onReset(...args)))
            }, "重置")
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_export_button, {
              onExport: _cache[6] || (_cache[6] = ($event: any) => (_ctx.getDerivationRoster()))
            }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode("导出名单")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_el_tabs, { "model-value": "detail" }, {
          default: _withCtx(() => [
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "header height-right" }, [
              _createElementVNode("div", { class: "" })
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_el_table, {
                data: _ctx.tableData,
                style: {"width":"100%"},
                ref: "dataTable",
                "header-row-style": { color: '#202536' },
                "row-style": { color: '#5E617D' },
                onSelectionChange: _ctx.handleSelectionChangeOne,
                "row-key": _ctx.rowKeyInit,
                type: "selection"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    type: "selection",
                    width: "55",
                    "reserve-selection": ""
                  }),
                  _createVNode(_component_el_table_column, {
                    type: "index",
                    label: "序号",
                    width: "100px",
                    "reserve-selection": ""
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "userName",
                    "reserve-selection": "",
                    label: "姓名",
                    width: "130px"
                  }),
                  _createVNode(_component_el_table_column, {
                    class: "card-no",
                    "reserve-selection": "",
                    prop: "idCardNo",
                    label: "身份证号"
                  }),
                  _createVNode(_component_el_table_column, {
                    label: "方案类型",
                    "reserve-selection": "",
                    prop: "comboName",
                    width: "140px"
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "serveCompanyName",
                    label: "被派遣单位"
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "insurStartTime",
                    label: "生效时间"
                  })
                ]),
                _: 1
              }, 8, ["data", "onSelectionChange", "row-key"]),
              _createVNode(_component_el_pagination, {
                class: "pagination",
                currentPage: _ctx.pageNum,
                "onUpdate:currentPage": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.pageNum) = $event)),
                "page-size": _ctx.pageSize,
                "onUpdate:pageSize": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.pageSize) = $event)),
                "page-sizes": [10, 30, 50, 100],
                layout: "total, sizes, prev, pager, next, jumper",
                total: _ctx.total,
                onSizeChange: _ctx.handleSizeChange,
                onCurrentChange: _ctx.getQueryInsureRecord
              }, null, 8, ["currentPage", "page-size", "total", "onSizeChange", "onCurrentChange"])
            ])
          ]),
          _: 1
        })
      ])
    ]),
    (_ctx.isFactory)
      ? (_openBlock(), _createBlock(_component_changeFactory, {
          key: 0,
          isFactory: _ctx.isFactory,
          onClose2: _ctx.close2,
          renewalManufactory: _ctx.renewalManufactory
        }, null, 8, ["isFactory", "onClose2", "renewalManufactory"]))
      : _createCommentVNode("", true)
  ], 64))
}