import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex gap-10" }
const _hoisted_2 = { class: "lable" }
const _hoisted_3 = { class: "lableContent mg-t-10" }
const _hoisted_4 = { class: "fs-26 bold" }
const _hoisted_5 = { class: "flex flex-jc-sb gap-10 fc-lightgray" }
const _hoisted_6 = { class: "flex" }
const _hoisted_7 = { class: "name" }
const _hoisted_8 = { class: "flex" }
const _hoisted_9 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, { shadow: "never" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.details, (i, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "info-item flex-1",
            key: index
          }, [
            _createElementVNode("div", {
              class: _normalizeClass([i.className || ''])
            }, [
              _createElementVNode("div", _hoisted_2, _toDisplayString(i.name), 1),
              _createElementVNode("div", _hoisted_3, [
                _cache[0] || (_cache[0] = _createTextVNode("¥")),
                _createElementVNode("span", _hoisted_4, _toDisplayString((i.allMoney || i.alrPrice || i.aitPrice || i.stopPrice) ?? 0), 1)
              ])
            ], 2),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "name" }, "发薪：", -1)),
                _createElementVNode("div", _hoisted_7, "¥ " + _toDisplayString((i.paymentTotalMoney || i.paymentAlrMoney || i.paymentWaitMoney || i.paymentStopMoney) ?? 0), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "name" }, "预支：", -1)),
                _createElementVNode("div", _hoisted_9, "¥ " + _toDisplayString((i.advanceTotalMoney || i.advanceAlrMoney || i.advanceWaitMoney || i.advanceStopMoney) ?? 0), 1)
              ])
            ])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}